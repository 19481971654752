/*
*list2
*/
.list2 {
    margin: 0px -12px;
    padding: 0px;
    list-style-type: none;
    li {
        float: left;
        width: 50%;
        &.box1 {
            padding: 0px 12px;
        }
    }
}

@include media-breakpoint-down(sm) { 
    .list2 {
        li {
            width: 100%;
            float: none;
        }
    }
}