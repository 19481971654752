/*
*popup
*/
.popup {
    .close {
        opacity: 1;
        span {
            position: relative;
            display: inline-block;
            width: 50px;
            height: 50px;
            overflow: hidden;
            &:before, &:after {
                content: '';
                position: absolute;
                height: 4px;
                margin-top: -2px;
                width: 100%;
                top: 50%;
                left: 0;
                background: $brand-primary;
            }
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }
    }
    .modal-dialog {
        max-width: 589px;
    }
    .modal-content {
        border-radius: 0px;
        padding: 64px 48px;
        position: relative;
        .close {
            position: absolute;
            top: 52px;
            right: 43px;
            float: none;
            z-index: 10;
        }
    }
    .modal-header, .modal-body {
        padding: 0px;
        border: 0px;
    }
    .modal-body {
        h2 {
            padding-right: 50px;
        }
    }
    .box1 {
        margin-bottom: 0px;
        .video {
            margin-bottom: 36px;
            .play-btn {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.modal-backdrop {
    background: #151f31;
    &.in {
        opacity: .9;
    }
}

@include media-breakpoint-down(sm) { 
    .popup { 
         .modal-content { 
             padding: 34px 12px;
             .close { 
                 top: 21px;
                 right: 12px;
             }
         }
    }
}