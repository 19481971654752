/*
*box1
*/
.box1 {
    margin-bottom: 37px;
    .video {
        position: relative;
        margin-bottom: 23px;
        -webkit-box-shadow: 0px 0px 17px 5px rgba(246,246,246,1);
        -moz-box-shadow: 0px 0px 17px 5px rgba(246,246,246,1);
        box-shadow: 0px 0px 17px 5px rgba(246,246,246,1);
        .overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0px; 
            left: 0px;
            background: #424c5d;
            opacity: 0.5;
            @include cubic-transition;
            opacity: 0;
            visibility: hidden;
        }
        .play-btn {
            width: 82px;
            height: 82px;
            border-radius: 50%;
            border: 6px solid #fff;
            @include absolute-center;
            @include cubic-transition;
            z-index: 1;
            opacity: 0;
            visibility: hidden;
            .fa-play {
                @include absolute-center;
                font-size: rem-calc(37);
                color: #fff;
                &:before {
                    margin-left: 6px;
                }
            }
        }
        a {
            &:hover {
                .overlay {
                    opacity: 0.5;
                    visibility: visible;
                }
                .play-btn {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    h3 {
        margin-bottom: 0px;
        padding-right: 100px;
    }
    .video-title {
        position: relative;
        .view-btn {
            @include vertical-center;
            right: 0px;
        }
    }
}