/*
*list1
*/
.list1 {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    > li {
        font-weight: 900;
        font-size: rem-calc(15);
        line-height: calc(18 / 15);
        padding: 15px 38px;
        border-left: 1px solid #8d939e;
        text-transform: uppercase;
        float: left;
        text-align: center;
    }
}