h1,h2 {
    font-family: 'braggadocio', sans-serif;
    line-height: calc(22 / 18);
    font-weight: 400;
    margin-bottom: 51px;
    text-transform: uppercase;
}

h2 {
    margin-bottom: 40px;
}

h3 {
    font-weight: 500;
    text-transform: uppercase;
    line-height: calc(21 / 15);
    margin-bottom: 5px;
}

@include media-breakpoint-down(md) { 
    h2 {
        font-size: rem-calc(17);
    }
    h3 {
        font-size: rem-calc(12);
        line-height: calc(17 / 12);
    }    
}

.full-width {
    width: 100%;
}