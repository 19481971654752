/*
*filter-category
*/
.filter-category {
    display: block;
    border-bottom: 1px solid #7a818d;
    position: relative;
    width: 100%;
    .title {
        font-weight: 500;
        display: block;
        font-size: rem-calc(14);
        text-transform: uppercase;
        line-height: calc(16 / 14);
        padding: 18px 41px 18px 16px;
        cursor: pointer;
        position: relative;
        &:after {
        //content: "\f0d7";
        content: "";
       // font-family: FontAwesome;
       // font-size: rem-calc(40);
       // color: $brand-primary;
        border-left: 11px solid transparent;
        border-right: 11px solid transparent;
        border-top: 11px solid $brand-primary;
        @include vertical-center;
        right: 14px;
        }
    }
    .filter-list {
        margin: 0px;
        padding: 0px;
        list-style-type: none;
        position: absolute;
        z-index: 1000;
        width: 100%;
        background: #fff;
        border: 1px solid #7a818d;
       // opacity: 0;
       // visibility: hidden; 
       display: none;
        @include cubic-transition;
        li {
            font-weight: 500;
            color: $brand-primary;
            text-transform: uppercase;
            margin-bottom: 2px;
            font-size: rem-calc(14);
            &:last-child {
                margin-bottom: 0px;
            }
            a {
                color: inherit;
                padding: 18px 16px;
                display: block;
                @include cubic-transition;
                &:hover {
                    background: $brand-primary;
                    color: $gray-dark;
                    @include cubic-transition;
                }
            }
        }
    }
    &:hover {
        .filter-list {
            //opacity: 1;
            //visibility: visible;
            //@include cubic-transition;
        }
    }
}
