/*
*form
*/
.form {
    .form-group {
        margin-bottom: 15px;
    }
    .captcha {
        .form-control {
            width: 50%;
            display: inline-block;
            margin-left: 33px;
        }
    }
    .form-control {
        color: #414b5d;
        font-weight: 500;
        font-size: rem-calc(14);
        text-transform: uppercase;
        border-radius: 0px;
        border: 0px;
        border-bottom: 1px solid #c0c4ca;
        padding-left: 0px;
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: #414b5d;
            opacity: 1;
        }
        &::-moz-placeholder { /* Firefox 19+ */
            color: #414b5d;
            opacity: 1;
        }
        &:-ms-input-placeholder { /* IE 10+ */
            color: #414b5d;
            opacity: 1;
        }
        &:-moz-placeholder { /* Firefox 18- */
            color: #414b5d;
            opacity: 1;
        }
    }
    .btn-primary {
        min-width: 151px;
    }
}

@include media-breakpoint-down(xs) { 
    .form {
        .captcha {
            padding-top: 15px;
            .form-control {
                display: block;
                width: 100%;
                margin-left: 0px;
                margin-top: 10px;
            }
        }
    }
}
