/*
*pagination
*/
.pagination {
    display: block;
    .page-item {
        display: inline-block;
        font-size: rem-calc(15);
        font-weight: 900;
        margin: 0px 1.3%;
        &.disabled {
            a {
                color: $brand-primary;
            }
        }
        a {
            border: 0px;
            color: $gray-dark;
            @include cubic-transition;
        }
        &:first-child, &:last-child {
            a {
                color: $brand-primary;
            }
        }
    }
}

@include media-breakpoint-down(sm) { 
    .pagination {
        .page-item {
            margin: 0px 1%;
        }
    }
}