/*
*header
*/
#header {
    position: relative;
    border-top: 13px solid $gray-dark;
    padding: 4px 0px;
    margin-bottom: 159px;
    top: 0px;
    width: 100%;
    z-index: 1000;
    background: #fff;
    @include cubic-transition;
    .logo {
       float: left;
        width:31%;
    }
    .list1 {
        float: left;
        width: 69%;
        padding-top: 34px;
         > li {
            &.filter-wrapper {
                text-align: left;
                border-left: 0px;
                position: absolute;
                width: 100%;
                padding: 53px 12px;
                left: 0px;
                background: #fff;
                box-shadow: inset 0px 20px 45px -10px #f4f4f4, inset 0px -20px 18px -10px #f4f4f4; 
                top: 100%;
                //@include cubic-transition;
                .filter-category {
                    max-width: 667px;
                    margin: 0px auto;
                }
            }
        }
    }
    &.scroll {
        border-color: #f6f6f6;
        padding-bottom: 0px;
        -webkit-box-shadow: 0px 7px 20px 0px rgba(246,246,246,1);
        -moz-box-shadow: 0px 7px 20px 0px rgba(246,246,246,1);
        box-shadow: 0px 7px 20px 0px rgba(246,246,246,1);   
        margin-bottom: 0px; 
        position: fixed;
        .filter-category {
            .title {
                color: $brand-primary;
                padding: 15px 41px 16px 16px;
                font-weight: 900;
            }
        }
        .list1 {
            li {
                &.filter-wrapper {
                    border-left: 1px solid #8d939e;
                    box-shadow: none;
                    position: relative;
                    padding: 0px 0px 0px 38px;
                    float: right;
                    width: calc(100% - 180px);
                }
            }
        }
    }
}

@include media-breakpoint-down(md) { 
    #header {
        .logo {
            img {
                width: 187px;
            }
        }
        .list1 {
            padding-top: 31px;
            > li {
                padding: 15px 24px;
            }
        }
        &.scroll {
            .list1 {
                > li {
                    &.filter-wrapper {
                        width: calc(100% - 151px);
                        padding: 0px 0px 0px 24px;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) { 
    #header {
        .logo {
            float: none;
            text-align: center;
            width: 100%;
            img {
                width: 160px;
            }
        }
        .list1 {
            padding-top: 0px;
            width: 100%;
            float: none;
            > li {
                float: none;
                border-left: 0px;
                border-top: 1px solid #8d939e;
                &.filter-wrapper { 
                    border-top: 0px;
                    .filter-category {
                        max-width: 516px;
                    }
                }
            }
        }
        &.scroll {
            .list1 {
                > li {
                    &.filter-wrapper {
                        border-top: 1px solid #8d939e;
                        width: 100%;
                        padding: 0px;
                        border-left: 0px;
                    }
                }
            }
            .filter-category {
                .title {
                    padding-top: 16px;
                }
            }
        }
    }
}

/*
*content
*/
#content {
    padding: 65px 0px;
    .pagination {
        margin-bottom: 24px;
    }
}

/*
*footer
*/
#footer {
    padding: 38px 0px 83px;
     -webkit-box-shadow: 0px 0px 17px 5px rgba(246,246,246,1);
    -moz-box-shadow: 0px 0px 17px 5px rgba(246,246,246,1);
    box-shadow: 0px 0px 17px 5px rgba(246,246,246,1);
    .logo {
        float: left;
        padding-top: 8px;
    }
    .list1 {
        margin-left: 163px;
        li {
            padding: 15px 45px;
        }
    }
}

@include media-breakpoint-down(sm) { 
    #footer {
        .logo {
            width: 100%;
            float: none;
            text-align: center;
            img {
                width: 160px;
            }
        }    
        .list1 {
            margin-left: 0px;
            float: none;
            width: 100%;
            li {
                border-left: 0px;
                border-top: 1px solid #8d939e;
                float: none;
            }
        }
    }
}
