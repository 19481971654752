body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  letter-spacing: 1px;
}

.group {
  @include clearfix();
}

img {
  max-width: 100%;
}

figure {
  margin: 0px;
}
