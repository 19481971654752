/*
*buttons
*/
.view-btn {
    text-transform: uppercase;
    font-size: rem-calc(15);
    line-height: calc(21 / 15);
    font-weight: 900;
}

.btn-primary {
    text-transform: uppercase;
}

@include media-breakpoint-down(md) { 
    .view-btn {
        font-size: rem-calc(11);
        line-height: calc(17 / 11);
    }
}
